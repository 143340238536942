/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}


#root{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left:0;
  top:0;
  overflow:hidden;
}

/*Material-UI overrides*/
.mui-top-container{
  box-sizing:border-box;

  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.mui-top-container a{
  color:unset;
}

.mui-top-container *,
.mui-top-container *::before,
.mui-top-container *::after{
  box-sizing:inherit;
}

.mui-top-container strong,
.mui-top-container b{
  font-weight:bolder;
}
body .MuiDrawer-root{
  position:relative;
}
body .MuiDrawer-paper{
  position:absolute;
  width:100%;
}



.mui-top-container{
 --scrollbg:transparent;
 --scrollColor:transparent;
}

.mui-top-container ::-webkit-scrollbar {
  height: 0px;
  width: 8px;
  height:8px;
  background:var(--scrollbg);
}
.mui-top-container ::-webkit-scrollbar-track-piece {
 background: transparent;
}
.mui-top-container ::-webkit-scrollbar-thumb:vertical,
.mui-top-container ::-webkit-scrollbar-thumb:horizontal {
 background: var(--scrollcolor);
 border-radius:20px;
} 

.hover-scroll:hover{
  --scrollbg:rgba(0,0,0,.1);
  --scrollcolor:rgba(0,0,0,.3);
}